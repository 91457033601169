<template>
    <v-row :class="['ma-0', $vuetify.breakpoint.mobile ? 'pl-5' : 'pa-0']" no-gutters>
        <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
        <v-col :class="[$vuetify.breakpoint.mobile ? 'mt-8' : 'mt-16']" :cols="$vuetify.breakpoint.mobile ? 11 : 8">
            <v-row class="mb-10 pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center" cols="12">
                    <span :class="[$vuetify.breakpoint.mobile ? 'px-8 py-2' : 'px-16 py-4']" style="border: 6px solid #f7ae22;">
                        <span :class="['font-weight-bold pointColor--text', $vuetify.breakpoint.mobile ? 'fontSizeOneDotEight' : 'fontSizeThree']">Nursing Home Development</span>
                    </span>
                </v-col>
                <v-col :class="[$vuetify.breakpoint.mobile ? 'py-8' : 'py-16']" cols="12">
                    <div class="d-flex justify-center align-center">
                        <span class="d-inline-block mr-1" style="width: 40px; height: 40px;">
                            <v-icon size="40">mdi-circle-small</v-icon>
                        </span>
                        <span :class="['d-inline-block font-weight-bold', $vuetify.breakpoint.mobile ? 'fontSizeOneDotTwo' : 'fontSizeOneDotEight']"
                            style="color: rgba(0, 0, 0, 0.54);">
                            Acquisition of Motheringcare Nursing Home in 2018
                        </span>
                    </div>
                    <div class="d-flex justify-center align-center mt-16">
                        <v-img :max-width="$vuetify.breakpoint.mobile ? 320 : 520" src="../../assets/motheringLogo1.png" alt="용인 마더링 로고"></v-img>
                    </div>
                    <div class="d-flex justify-center align-center mt-16">
                        <span class="d-inline-block mr-1" style="width: 40px; height: 40px;">
                            <v-icon size="40">mdi-circle-small</v-icon>
                        </span>
                        <span :class="['d-inline-block font-weight-bold', $vuetify.breakpoint.mobile ? 'fontSizeOneDotTwo' : 'fontSizeOneDotEight']" style="color: rgba(0, 0, 0, 0.54);">
                            Opening of Youngtong Mothering Nursing Home in 2020
                        </span>
                    </div>
                    <div class="d-flex justify-center align-center mt-16">
                        <v-img :max-width="$vuetify.breakpoint.mobile ? 320 : 520" src="../../assets/motheringLogo2.png" alt="영통 마더링 로고"></v-img>
                    </div>
                    <div class="d-flex justify-center align-center mt-16">
                        <span class="d-inline-block mr-1" style="width: 40px; height: 40px;">
                            <v-icon size="40">mdi-circle-small</v-icon>
                        </span>
                        <span :class="['d-inline-block font-weight-bold', $vuetify.breakpoint.mobile ? 'fontSizeOneDotTwo' : 'fontSizeOneDotEight']" style="color: rgba(0, 0, 0, 0.54);">
                            Opening of Mothering Rehabilitation Nursing Home in 2024
                        </span>
                    </div>
                    <div class="d-flex justify-center align-center mt-16">
                        <v-img src="../../assets/hwaNursing.png" alt="마더링 신축"></v-img>
                    </div>
                    <div class="d-flex justify-center align-center mt-16">
                        <v-img :max-width="$vuetify.breakpoint.mobile ? 320 : 520" src="../../assets/motheringLogo3.png" alt="화성 마더링 로고"></v-img>
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
    </v-row>
</template>

<script>
export default {
    name: 'NrsnHomeDvlp',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        
    },
    
    data: () => ({

    }),
};
</script>