<template>
    <v-row class="my-2" no-gutters>
        <v-col class="" cols="12">
            <div class="d-flex align-center">
                <span class="d-inline-block mr-5" style="width: 40px;">
                    <span class="d-inline-block d-inline-block d-flex align-center appBarSubMenu">
                        <v-img
                        alt="해리지앤디 로고"
                        class="shrink mr-2" width="40"
                        src="../../assets/harrygndLogo.png"
                        transition="scale-transition" contain>
                        </v-img>
                    </span>
                </span>
                <v-row class="pa-0 ma-0" no-gutters>
                    <v-col cols="12">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="d-flex justify-left align-center" cols="1">
                                <v-icon class="pt-1" size="16">mdi-tag-text</v-icon>
                            </v-col>
                            <v-col class="d-flex justify-left align-center" cols="5">
                                <span class="" style="font-size: 0.875rem;">㈜해리지앤디</span>
                            </v-col>
                            <v-col class="d-flex justify-left align-center" cols="1">
                                <v-icon class="pt-1" size="16">mdi-phone-classic</v-icon>
                            </v-col>
                            <v-col class="d-flex justify-left align-center" cols="5">
                                <span class="" style="font-size: 0.875rem;">031-206-1256</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="d-flex justify-left align-center" cols="1">
                                <v-icon class="pt-1" size="16">mdi-account-tie</v-icon>
                            </v-col>
                            <v-col class="d-flex justify-left align-center" cols="5">
                                <span class="" style="font-size: 0.875rem;">이승준</span>
                            </v-col>
                            <v-col class="d-flex justify-left align-center" cols="1">
                                <v-icon class="pt-1" size="16">mdi-fax</v-icon>
                            </v-col>
                            <v-col class="d-flex justify-left align-center" cols="5">
                                <span class="" style="font-size: 0.875rem;">031-206-1258</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="d-flex justify-right align-center" cols="1">
                                <v-icon class="pa-0 ma-0" size="18">mdi-map-marker-outline</v-icon>
                            </v-col>
                            <v-col class="d-flex justify-right align-center" cols="10">
                                <span class="txt_line" style="font-size: 0.875rem;">경기도 용인시 기흥구 용구대로 2469번길 164, 402호</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="d-flex justify-right align-center" cols="1">
                                <v-icon class="pa-0 ma-0" size="18">mdi-email-outline</v-icon>
                            </v-col>
                            <v-col class="txt_line" cols="10">
                                <span class="d-flex justify-right align-center" style="font-size: 0.875rem;">info@harrygnd.com</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="d-flex justify-right align-center" style="padding-left: 1px;" cols="1">
                                <v-icon class="pa-0 ma-0" size="16">mdi-card-account-details-outline</v-icon>
                            </v-col>
                            <v-col class="txt_line" cols="10">
                                <span class="d-flex justify-right align-center" style="font-size: 0.875rem;">762-81-01713</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0 mt-1" no-gutters>
                            <v-col class="d-flex justify-right align-center" cols="12">
                                <span class="d-block font-weight-thin grey--text darken-1" style="font-size: 0.75rem;">
                                    Copyright 2023 ⓒ ㈜해리지앤디. All right reserved.
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'CmFooterMobile',
        
    components: {

    },
    
    props: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {

    },
    
    data: () => ({

    }),
};
</script>